
.labels-row{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.fullwidth-mobile{
  @media (max-width: 768px){
    width: 100%;
    margin-bottom: 0 !important;

  }
}

.skill-level-large{
  @media(max-width: 768px){
    display: none;
  }
}

.skill-level-small{
  @media(min-width: 769px){
    display: none;
  }
}

.skill-slider{
  transition: color 0.2s ease-in-out;

  &.color-10{
    color: #ff5959;
  }

  &.color-30{
    color: #ff9159;
  }

  &.color-50{
    color: #ee9316;
  }

  &.color-70{
    color: #88c813;
  }

  &.color-90{
    color: #39a158;
  }

  .MuiSlider-thumb{
    height: 40px;
    width: 20%;
    border-radius: 10px;
    box-shadow: none !important;
  }
}