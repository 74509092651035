.information-card {
  background-color: #fff;
  margin-bottom: 2.5rem;
  box-shadow: 0px 0px 15px 2.5px rgba(0, 0, 0, 0.1);
  padding: 20px 40px;

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
    padding: 15px 20px;
  }

  .information-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .header-div{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;


      .leading-icon{
        width: 64px;
        height: 64px;
        margin-right: 10px;
      }
    }

    @media(max-width: 768px){
      flex-wrap: wrap;
      justify-content: flex-end;

      .header-div{
        flex-basis: 100%;
      }

      .apply-button{
        margin-top: 10px;
      }
    }
  }

  div.solid {
    height: 2px;
    background-color: #ccc;
    border-radius: 4px;
    margin: 10px 0;
  }

  div.solid-thin {
    height: 1px;
    background-color: #ccc;
    border-radius: 4px;
    margin: 10px 0;
  }

  .information-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0;

    .label {
      font-weight: bold;
      flex: 1;
      min-width: 150px;
      text-align: end;
      padding-right: 2rem;

      @media (max-width: 768px){
        text-align: start;
      }
    }

    .content {
      flex: 3.25;
      flex-basis: 100%;
      overflow-wrap: anywhere;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .skills-row {

    .label {
      font-weight: 500;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .display {
      padding-top: 5px;
      padding-bottom: 10px;
    }
  }
}