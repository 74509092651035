.card-item{
    width: 100%;
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex;
    flex-direction: row;

    .leading-icon-container{
        width: 65px;
        height: 65px;

        .leading-icon{
            width: 65px;
            height: 65px;
            color: var(--dark-primary)
        }

        .dark-blue{
            filter: brightness(0) saturate(100%) invert(20%) sepia(64%) saturate(658%) hue-rotate(176deg) brightness(95%) contrast(93%);
        }


        @media (max-width: 768px){
            width: 45px;
            height: 45px;

            .leading-icon{
                width: 45px;
                height: 45px;
            }
        }
    }

    .card-item-content-container{
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        flex: 1;

        .card-title{
            font-weight: bold;
            font-size: 16px;
            overflow-wrap: anywhere;
        }

        .card-subtitle{
            font-size: 14px;
            overflow-wrap: anywhere;
        }

        .card-time-span{
            font-size: 14px;
            opacity: 0.85;
            overflow-wrap: anywhere;
        }

        .card-location{
            font-size: 14px;
            opacity: 0.85;
            overflow-wrap: anywhere;
        }

        .card-description{
            font-size: 14px;
            opacity: 0.85;
            overflow-wrap: anywhere;
        }
    }

    .card-item-edit-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .edit-icon{
            color: black
        }
    }
}