@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
input {
    font-family: "Poppins", sans-serif;
}

.error{
    color: red;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

.logo{
    /*width: 200px;*/
    height: 175px;
}

.sign-up-form,
.sign-in-form {
    margin-bottom: 50px;
}

.no-margin{
    margin: 0;
}

.login-container {
    position: relative;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;
    overflow: hidden;
}

.forms-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.signin-signup {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 75%;
    width: 50%;
    transition: .9s .85s ease-in-out;
    display: grid;
    grid-template-columns: 1fr;
    z-index: 5;
}

.login-container form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0rem 5rem;
    transition: all 0.2s 1s;
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.login-container form.sign-up-form {
    opacity: 0;
    z-index: 1;
}

.login-container form.sign-in-form {
    z-index: 2;
}

.title {
    font-size: 2.2rem;
    color: #444;
    margin-bottom: 10px;
}

.input-field {
    max-width: 380px;
    width: 100%;
    background-color: #f0f0f0;
    margin: 10px 0;
    height: 55px;
    border-radius: 55px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 0.4rem;
    position: relative;
}

.input-field i {
    text-align: center;
    line-height: 55px;
    color: #acacac;
    transition: 0.5s;
    font-size: 1.1rem;
}

.input-field input {
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
}

.input-field input::placeholder {
    color: #aaa;
    font-weight: 500;
}

.social-text {
    padding: 0.7rem 0;
    font-size: 1rem;
}

.register-text {
    padding: 0.7rem 0;
    font-size: 1rem;
}

.social-media {
    display: flex;
    justify-content: center;
}

.social-icon {
    height: 46px;
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.45rem;
    color: #333;
    border-radius: 50%;
    border: 1px solid #333;
    text-decoration: none;
    font-size: 1.1rem;
    transition: 0.3s;
    cursor: pointer;
}

.social-icon:hover {
    color: var(--dark-primary);
    border-color: var(--dark-primary);
}

.button {
    width: 160px;
    background-color: var(--dark-primary);
    border: none;
    outline: none;
    height: 49px;
    line-height: 49px;
    border-radius: 49px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px 0;
    text-align: center;
    font-size: 0.825rem;
    cursor: pointer;
    transition: 0.35s;
}

.button:hover {
    box-shadow: 0 3px 6px 0 rgba(5, 21, 46, 0.16);
    background-color: var(--darker-primary);
    transform: translate(0px, -2px);
}

.panels-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.login-container:before {
    content: "";
    position: absolute;
    height: 2000px;
    width: 2000px;
    top: -10%;
    right: 48%;
    transform: translateY(-50%);
    background-image: linear-gradient(-45deg, var(--dark-primary) 0%, var(--dark-primary-gradient) 100%);
    transition: 1.8s ease-in-out;
    border-radius: 50%;
    z-index: 6;
}

.image {
    width: 100%;
    transition: transform 1.1s ease-in-out;
    transition-delay: 0.4s;
}

.image.smaller{
    width: 85%;
}

.panel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    text-align: center;
    z-index: 6;
}

.left-panel {
    pointer-events: all;
    padding: 3rem 17% 2rem 12%;
}

.right-panel {
    pointer-events: none;
    padding: 3rem 12% 2rem 17%;
}

.panel .content {
    color: #fff;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
}

.panel h3 {
    font-weight: 600;
    line-height: 1;
    font-size: 1.5rem;
}

.panel p {
    font-size: 0.95rem;
    padding: 0.7rem 0;
}

.input{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.button.transparent {
    margin: 0;
    background: none;
    border: 2px solid #fff;
    width: 170px;
    height: 49px;
    font-weight: 600;
    font-size: 0.8rem;
}

.button.primary-border {
    border: 2px solid var(--dark-primary);
    color: var(--dark-primary);
    width: auto;
    padding: 0 16px;
    background-color: transparent;
    margin-top: 0;
}

.right-panel .image,
.right-panel .content {
    transform: translateX(1000px);
}

/* ANIMATION */

.login-container.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 52%;
}

.login-container.sign-up-mode .left-panel .image,
.login-container.sign-up-mode .left-panel .content {
    transform: translateX(-1000px);
}

.login-container.sign-up-mode .signin-signup {
    left: 25%;
}

.login-container.sign-up-mode form.sign-up-form {
    opacity: 1;
    z-index: 2;
}

.login-container.sign-up-mode form.sign-in-form {
    opacity: 0;
    z-index: 1;
}

.login-container.sign-up-mode .right-panel .image,
.login-container.sign-up-mode .right-panel .content {
    transform: translateX(0%);
}

.login-container.sign-up-mode .left-panel {
    pointer-events: none;
}

.login-container.sign-up-mode .right-panel {
    pointer-events: all;
}

.login-help-button {
    z-index: 10;
    top: 10px;
    transition: left 1.8s ease-in-out, top 1.8s ease-in-out !important;
}

.login-help-button.login {
    left: 10px;
}

.login-help-button.signup {
    left: calc(100% - 50px);
}

@media (max-width: 870px) {
    .title{
        margin: 0;
        font-size: 1.9rem;
        color: #444;
    }

    .logo{
        width: 100%;
        height: 100%;
    }

    .sign-in-form,
    .sign-up-form{
        margin-bottom: 0;
    }

    .login-container {
        min-height: 850px;
        height: 100vh;
        overflow-x: hidden;
    }

    .signin-signup {
        width: 100%;
        top: 95%;
        transform: translate(-50%, -100%);
        transition: 1s 0.8s ease-in-out;
    }

    .signin-signup,
    .login-container.sign-up-mode .signin-signup {
        left: 50%;
    }

    .panels-container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr 1fr;
    }

    .panel {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 2.5rem 8%;
        grid-column: 1 / 2;
    }

    .right-panel {
        grid-row: 3 / 4;
    }

    .left-panel {
        grid-row: 1 / 2;
    }

    .image {
        width: 200px;
        transition: transform 0.9s ease-in-out;
        transition-delay: 0.6s;
    }

    .panel .content {
        padding-right: 15%;
        transition: transform 0.9s ease-in-out;
        transition-delay: 0.8s;
    }

    .panel h3 {
        font-size: 1.2rem;
    }

    .panel p {
        font-size: 0.8rem;
        padding: 0.5rem 0;
    }

    .button.transparent {
        width: 135px;
        height: 40px;
        line-height: 40px;
        font-size: 0.7rem;
    }

    .social-text{
        text-align: center;
    }

    .register-text{
        text-align: center;
        padding: 0;
    }

    .login-container:before {
        width: 1500px;
        height: 1500px;
        transform: translateX(-50%);
        left: 30%;
        bottom: 68%;
        right: initial;
        top: initial;
        transition: 2s ease-in-out;
    }

    .login-container.sign-up-mode:before {
        transform: translate(-50%, 100%);
        bottom: 32%;
        right: initial;
    }

    .login-container.sign-up-mode .left-panel .image,
    .login-container.sign-up-mode .left-panel .content {
        transform: translateY(-300px);
    }

    .login-container.sign-up-mode .right-panel .image,
    .login-container.sign-up-mode .right-panel .content {
        transform: translateY(0px);
    }

    .right-panel .image,
    .right-panel .content {
        transform: translateY(300px);
    }

    .login-container.sign-up-mode .signin-signup {
        top: 10px;
        transform: translate(-50%, 0);
    }

    .input-field {
        max-width: 380px;
        width: 100%;
        background-color: #f0f0f0;
        margin: 7.5px 0;
        height: 50px;
        border-radius: 50px;
        display: grid;
        grid-template-columns: 15% 85%;
        padding: 0 0.4rem;
        position: relative;
    }

    .input-field i {
        text-align: center;
        line-height: 50px;
        color: #acacac;
        transition: 0.5s;
        font-size: 1rem;
    }

    .input-field input {
        background: none;
        outline: none;
        border: none;
        line-height: 1;
        font-weight: 600;
        font-size: 1rem;
        color: #333;
    }

    .input-field input::placeholder {
        color: #aaa;
        font-weight: 500;
    }

    .image {
        display: none;
    }
}

@media (max-width: 570px) {
    .login-container form {
        padding: 0 1.5rem;
    }

    .panel .content {
        padding: 0.5rem 1rem;
    }
    .login-container {
        padding: 1.5rem;
    }

    .login-container:before {
        bottom: 72%;
        left: 50%;
    }

    .login-container.sign-up-mode:before {
        bottom: 28%;
        left: 50%;
    }

    .login-help-button {
        left: 0 !important;
        transition: left 2s ease-in-out, top 2s ease-in-out !important;
    }

    .login-help-button.login {
        top: 0;
    }

    .login-help-button.signup {
        top: calc(100% - 40px);
    }

    .submit-button{
        margin-top: 0;
    }
}

