.page-container {
  position: relative;
}

#reverse-div {
  position: fixed;
  top: 50px;
  margin-left: auto;
  z-index: 5;

  @media(min-width: 769px) {
    width: 20rem;
  }

  .reverse-profile-container {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 15px 2.5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    padding: 20px 0;

    a {
      all: unset;
      cursor: pointer;
      color: var(--dark-primary)
    }

    .profile-picture-container {
      width: 37.5%;
      aspect-ratio: 1;
      border-radius: 100%;
      margin: 5px auto;
      overflow: hidden;
      position: relative;

      .profile-picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .profile-icon {
        width: 100%;
        height: 100%;
        color: white;
        background-color: var(--dark-primary);
      }
    }

    .profile-name {
      text-align: center;
      padding: 0 20px;
    }

    .profile-type {
      text-align: center;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
    }

    .contact-info {
      padding: 0 35px;
      display: flex;
      flex-direction: column;
      align-items: start;
      max-width: 100%;

      .contact-row {
        display: flex;
        padding: 2px 0;
        max-width: 100%;

        .contact-content {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .contact-icon {
          width: 1em;
          height: 1em;
          margin-right: 4px;
          color: grey;
        }
      }
    }

    .social-icons-row {
      display: flex;
      align-items: center;
      justify-content: center;

      .profile-social-icon {
        margin: 0 3px;
        width: 30px;
        height: 30px;
        opacity: 0.7;
      }
    }
  }

  .job-location-container {
    aspect-ratio: 1;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 15px 2.5px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
    margin-bottom: 40px;
    overflow: hidden;

    @media (max-width: 768px) {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
}

.reverse-information-container {
  margin-right: 23rem;
  padding-top: 50px;
  z-index: 1;
  position: relative;
  overflow-wrap: anywhere;
}

@media (max-width: 768px) {
  .page-container {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .reverse-information-container {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      //padding-top: 40px;
      width: 100vw;
    }

    #reverse-div {
      padding-left: 1rem;
      padding-right: 1rem;
      max-width: 100vw;
      width: 100vw;
      position: relative;
      top: 25px;
      padding-bottom: 25px;
    }
  }
}
