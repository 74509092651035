.new-job-button {
  background-color: var(--dark-primary);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), 0 14px 14px 0 rgba(0, 0, 0, 0.2);
  color: white;
  border-radius: 12px;
  padding: 14px 24px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transition: transform 300ms ease, box-shadow 300ms ease, background-color 300ms ease, -webkit-transform 300ms ease;

  &.not-selected{
    background-color: white;
    color: #47505c;

    &:hover{
      background-color: var(--dark-primary);
    }
  }

  &.pagination-button{
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (max-width: 768px){
    margin-top: 24px;
  }

  &:hover{
    background-color: var(--darker-primary);
    box-shadow: 0 3px 9px 0 rgba(5, 21, 46, 0.16), 0 14px 19px 0 rgba(21, 60, 245, 0.23);
    -webkit-transform: translate(0px, -2px);
    -ms-transform: translate(0px, -2px);
    transform: translate(0px, -2px);
    color: white
  }
}

.jobs-page {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .jobs-list-container {
    max-width: 100%;
    flex: 5;
  }

  .jobs-sidebar-large {
    flex: 2;
    margin-top: 30px;
    margin-left: 30px;

    @media (max-width: 768px){
      display: none;
    }
  }
}

.jobs-list-container{
  @media (max-width: 768px){
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.no-padding{
  padding: 0 !important;
}

.jobs-sidebar-small {
  margin-top: -10px;
  margin-left: 1rem;
  margin-right: 1rem;

  input {
    margin-left: 3rem;
    width: calc(100% - 3rem) !important;
  }

  @media (min-width: 769px){
    display: none;
  }
}

.sidebar-holder {
  position: sticky;
  top: 20px;

  .sidebar-title {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
  }

  .sidebar-search-input {
    width: 100%;
    min-height: 72px;
    padding-right: 24px;
    padding-left: 61px;
    border-style: solid;
    border-width: 1px;
    border-color: #f7f7fb;
    border-radius: 10px;
    background-image: url('../../images/svgs/search.svg');
    background-position: 20px 50%;
    background-size: auto;
    background-repeat: no-repeat;
    box-shadow: 0 20px 24px 0 rgba(21, 60, 245, 0.06), 0 5px 14px 0 rgba(5, 21, 46, 0.05);
    -webkit-transition: box-shadow 350ms ease, border-color 350ms ease;
    transition: box-shadow 350ms ease, border-color 350ms ease;
    color: #858c95;
    font-size: 20px;
    line-height: 38px;
  }

  .sidebar-search-input:hover {
    border-color: var(--light-primary);
    box-shadow: 0 24px 33px 0 rgba(21, 60, 245, 0.1), 0 7px 18px 0 rgba(5, 21, 46, 0.07);
  }

  .sidebar-search-input:focus {
    border-color: var(--light-primary);
    color: #05152e;
  }

  .sidebar-search-input:focus-visible {
    outline: none;
  }

  .sidebar-search-input::-webkit-input-placeholder {
    color: #858c95;
  }

  .sidebar-search-input:-ms-input-placeholder {
    color: #858c95;
  }
}

.pagination-container{
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--dark-primary)
}