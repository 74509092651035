.application-arrow{
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;

  @media (max-width: 768px){
    position: initial;
    margin: 20px 0;
    justify-content: center;
  }

  .rotate-down{
    transform: rotate(60deg);
  }

  svg{
    width: 32px;
    height: 32px;
  }

  .new-job-button{
    padding: 10px 16px;
    font-size: 14px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12),
    0 7px 7px 0 rgba(0, 0, 0, 0.2);

    &.reject-button{
      background-color: red;
      color: black;
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translateX(-50%);

      &:hover{
        box-shadow: 0 3px 9px 0 rgba(46, 5, 5, 0.16), 0 14px 19px 0 rgba(240, 19, 34, .23);
        transform: translate(-50%, -2px);
      }

      @media (max-width: 768px){
        bottom: -20px;
      }
    }
  }
}

.application-creation-date{
  position: absolute;
  bottom: 5px;
  right: 15px;

  color: #858c95;
  line-height: 20px;
  font-weight: 400;
  text-align: right;
  font-size: 16px;

  @media (max-width: 768px){
    right: auto;
    bottom: 30px;
    left: 10px;
  }
}

.delete-application-button{
  position: absolute !important;
  right: 1px;
  top: 1px;

  .delete-application-icon{
    color: rgba(0,0,0,.8);
  }
}