.jobs-filter-card{
  width: 100%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 7px 8px 0 rgba(30, 54, 170, 0.08);
  margin-top: 30px;
  padding: 20px 30px;

  div.solid {
    height: 2px;
    background-color: #ccc;
    border-radius: 4px;
    margin: 10px 0;
  }

  .filter-edit-button{
    background-color: #f6f7fa;
    color: #D53B3B;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.08em;
    transition: color 400ms ease, background-color 400ms ease;
    border-radius: 6px;
    width: 100%;

    &:hover{
      background-color: #D53B3B;
      color: white;
    }
  }

  .filters-container{
    color: #47505c;
    letter-spacing: 0.06em;
    font-weight: 500;
  }
}