.vertical-divider{
  min-height: 100px;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  opacity: 0.5;
  margin-left: 20px;
  margin-right: 15px;

  @media (max-width: 768px){
    display: none;
  }
}