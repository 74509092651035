.container-form{
  .grey-subtitle{
    color: #47505c;
    opacity: .75;
    font-weight: 400;
  }
}

.accordion-text-field{
  width: 75% !important;
  margin-bottom: 7.5px !important;
}

.error-paragraph{
  margin-bottom: 7.5px !important;
}