.list-item {
  border-radius: 20px;
  background-color: white;
  width: 100%;
  position: relative;

  display: flex;
  padding: 30px;
  box-shadow: 0 -2px 6px 0 rgba(5, 21, 46, 0.02), 0 6px 12px 0 rgba(21, 60, 245, 0.05), 0 2px 6px 0 rgba(5, 21, 46, 0.02);
  margin: 30px 0;

  @media (max-width: 768px) {
    padding: 20px;
    flex-direction: column;
  }

  .company-logo {
    width: 104px;
    height: 104px;
    box-shadow: 0 2px 6px 0 rgba(5, 21, 46, 0.09), 0 4px 8px 0 rgba(21, 60, 245, 0.06);
    border-radius: 26px;
    overflow: hidden;
    transition: transform 400ms ease, box-shadow 400ms ease, -webkit-transform 400ms ease;
    margin-right: 24px;

    &.is-application-logo {
      margin-left: 24px;
    }

    &:hover {
      box-shadow: 0 4px 10px 0 rgba(5, 21, 46, 0.15), 0 6px 12px 0 rgba(21, 60, 245, 0.09);
      transform: translate(0px, -4px);
    }

    .profile-icon {
      width: 100%;
      height: 100%;
      color: white;
      background-color: var(--dark-primary);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 768px) {
      width: 90px;
      height: 90px;
      border-radius: 22px;
      margin-bottom: 10px;
      &.is-application-logo {
        margin-left: auto;
      }    }
  }

  .job-body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    //overflow: hidden;

    @media (max-width: 768px){
      flex-direction: column;
    }

    div.solid{
      height: 2px;
      background-color: #ccc;
      border-radius: 4px;
      margin: 20px 0;
      width: 100%;

      @media (min-width: 769px){
        display: none
      }
    }

    .job-info-primary {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 22px;
      overflow: hidden;

      .company-name {
        cursor: pointer;
        transition: color 400ms ease;
        line-height: 22px;
        font-weight: 500;
        text-decoration: none;
        font-size: 18px;
        color: var(--light-primary);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @media (max-width: 768px){
          white-space: pre-wrap;
        }
      }

      .job-name {
        margin-top: 4px;
        margin-bottom: 14px;
        transition: transform 400ms ease, color 400ms ease, -webkit-transform 400ms ease;
        line-height: 32px;
        text-decoration: none;
        color: black;

        @media (max-width: 768px) {
          margin-bottom: 0;
        }

        h3 {
          font-size: 24px;
        }

        &:hover {
          transform: translate(0px, -3px);
          color: var(--light-primary);
        }
      }

      .job-card-info-bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .job-type-container {
          display: flex;
          align-items: center;

          .job-type-icon {
            margin-right: 12px;
          }

          .job-type-name {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            font-weight: 400;
          }
        }

        @media (max-width: 768px) {
          &:not(.application-card-info-bottom) {
            justify-content: center;
          }

          .job-badge {
            margin-top: 15px;
          }

          .job-type-container {
            margin-top: 15px;
          }
        }
      }
    }

    .job-info-secondary {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      height: 100%;
      
      @media (max-width: 768px){
        flex-direction: row-reverse;
        width: 100%;
        align-items: center;

        .new-job-button{
          margin-top: 0;
        }
      }

      color: #858c95;
      line-height: 20px;
      font-weight: 400;
      text-align: right;
      font-size: 18px;

      .new-job-button {
        padding: 10px 16px;
        font-size: 14px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12),
        0 7px 7px 0 rgba(0, 0, 0, 0.2);

        &.reject-button {
          background-color: red;
          color: black;
        }
      }
    }
  }
}

.job-badge {
  all: unset;
  cursor: pointer;

  padding: 8px 12px;
  border-radius: 6px;
  background-color: #f6f7fa;
  -webkit-transition: color 400ms ease, background-color 400ms ease;
  transition: color 400ms ease, background-color 400ms ease;
  color: #47505c;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.12em;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;

  &.card {
    display: block;
    margin-right: 20px;
  }

  &:hover {
    background-color: var(--light-primary);
    color: white;
  }

  &.svg-hover-white {
    svg {
      transition: filter 400ms ease, -webkit-filter 400ms ease;
      filter: invert(19%) sepia(31%) saturate(1826%) hue-rotate(183deg) brightness(98%) contrast(90%);
      -webkit-filter: invert(19%) sepia(31%) saturate(1826%) hue-rotate(183deg) brightness(98%) contrast(90%);
    }

    &:hover {
      svg {
        filter: invert(100%) sepia(3%) saturate(1436%) hue-rotate(248deg) brightness(121%) contrast(100%);
        -webkit-filter: invert(100%) sepia(3%) saturate(1436%) hue-rotate(248deg) brightness(121%) contrast(100%);
      }
    }
  }
}

.applied-to-job-button {
  background-color: #ffc225 !important;
  color: black !important;
  padding: 10px 16px !important;
  font-size: 14px;
  font-weight: 700 !important;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase !important;
}

.favorite-job-container {
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 768px){
    top: 0;
    right: 0;
    left: auto;
  }

  .favorite-job-button {
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 2px;

    .favorite-job-icon {
      height: 28px;
      width: 28px;
    }
  }

  .favorite-job-circular-progress {
    width: 26px !important;
    height: 26px !important;
    margin: 7.5px;
  }
}