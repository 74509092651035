@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.header {
  height: 350px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  @media (max-width: 768px){
    height: 250px;
  }

  .header-edit-buttons-container{
    position: absolute;
    top: 5px;
    right: 5px;

    .header-edit-buttons-background {
      background: rgba(255,255,255,0.25);
      display: flex;
      flex-direction: column;
      border-radius: 20px;

      .header-icon {
        color: black;
      }
    }
  }
}

.edit-button {
  position: absolute !important;
  top: 5px;
  right: 5px;

  .edit-icon {
    color: black;
  }
}

.rounded {
  border-radius: 20px;
}

.page-container {

  .profile-container {
    @media(min-width: 769px) {
      width: 20rem;
    }
    background-color: #fff;
    position: fixed;
    top: 125px;
    box-shadow: 0 0 15px 2.5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    padding: 20px 0;
    z-index: 5;

    a {
      all: unset;
      cursor: pointer;
      color: var(--dark-primary)
    }

    .profile-picture-container {
      width: 37.5%;
      aspect-ratio: 1;
      border-radius: 26px;
      box-shadow: 0 3px 9px 0 rgba(5, 21, 46, 0.12), 0 6px 12px 0 rgba(21, 60, 245, 0.085);
      margin: 5px auto;
      overflow: hidden;
      position: relative;

      .profile-picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .profile-icon {
        width: 100%;
        height: 100%;
        color: white;
        background-color: var(--dark-primary);
      }

      .profile-picture-edit-container{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 5;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        .edit-icon{
           color: white;
        }

        &:hover{
          opacity: 1;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .profile-name {
      text-align: center;
      padding: 0 20px;
    }

    .profile-type {
      text-align: center;
      padding: 5px 5px 10px;
    }

    .contact-info {
      padding: 0 35px;
      display: flex;
      flex-direction: column;
      align-items: start;
      overflow: hidden;

      .contact-row {
        display: flex;
        padding: 2px 0;
        max-width: 100%;

        &.cv-row{
          cursor: pointer;
          color: var(--dark-primary);
        }

        .contact-content {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .contact-icon {
          width: 1em;
          height: 1em;
          margin-right: 4px;
          color: grey;
        }

        .cv-contact-icon{
          width: 1.5em;
          height: 1.5em;
          margin-right: 4px;
          filter: invert(54%) sepia(0%) saturate(394%) hue-rotate(170deg) brightness(93%) contrast(88%);
        }
      }
    }

    .social-icons-row {
      display: flex;
      align-items: center;
      justify-content: center;

      .profile-social-icon {
        margin: 0 3px;
        width: 30px;
        height: 30px;
        opacity: 0.7;
      }
    }
  }

  .information-container {
    margin-left: 25rem;
    width: calc(100% - 25rem);
    padding-top: 225px;
    z-index: 1;
    position: relative;

  }
}

.phone-container {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

@media (max-width: 768px) {
  .page-container {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .information-container {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 200px;
      width: 100vw;
    }

    .profile-container-holder, .reverse-profile-container-holder {
      padding-left: 1rem;
      padding-right: 1rem;
      max-width: 100vw;
      width: 100vw;

      .profile-container, .reverse-profile-container {
        max-width: 100vw;
        position: relative;
        top: 125px;
      }
    }
  }
}

.form-dialog{

  .form-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &.space-between{
      align-items: flex-end;
      justify-content: space-between;
    }

    .left{
      margin-right: 12.5px;
    }

    .right{
      margin-left: 12.5px;
    }

    @media (max-width: 768px){
      flex-direction: column;

      .left{
        margin-right: 0;
      }

      .right{
        margin-left: 0;
      }
    }


  }
  .form-text-field{
    margin-bottom: 10px;
  }

  .cv-icon{
    padding: 3.5px;
  }
}

.chip-job-badge{
  cursor: pointer !important;
}

