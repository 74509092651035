.information-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .header-div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .leading-icon {
      width: 64px;
      height: 64px;
      margin-right: 10px;
    }
  }
}