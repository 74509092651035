.jobs-container {

  .job-card{
    cursor: pointer;
    display: flex;
    flex-direction: row;
  }

  .leading-icon-container {
    width: 65px;
    height: 65px;

    @media (max-width: 768px) {
      width: 45px;
      height: 45px;
    }

    .leading-image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .job-card-button-container-large{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;

    @media(max-width: 768px){
      display: none;
    }
  }

  .job-card-button-container-small{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 7.5px;

    @media(min-width: 769px){
      display: none;
    }
  }
}

.apply-button{
  color: white;
  background-color: var(--dark-primary);
  padding: 5px 15px;
  border-radius: 20px;
  transition: transform 300ms ease, box-shadow 300ms ease, background-color 300ms ease, -webkit-transform 300ms ease !important;
  text-transform: uppercase !important;
  letter-spacing: 0.08em;
  font-weight: 600 !important;

  &:hover{
    box-shadow: 0 3px 6px 0 rgba(5, 21, 46, 0.16);
    background-color: var(--darker-primary);
    transform: translate(0px, -2px);
  }
}