.layout-container {
  :has(.register-page-container) {
    overflow-x: auto;
  }
}

.register-page-container{
  height: 100vh;
  width: 100vw;
  min-width: 1200px;
  overflow: auto;
  display: flex;
  flex-direction: row;
  position: relative;

  .steps-container{
    flex: 1;
    background-color: var(--dark-primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .current-step-details{
      padding: 0 50px 50px 50px;
    }

    .text-container{
      padding: 0 50px;

      .connector{
        height: 35px;
        border: 1px solid grey;
        width: 0;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 14px;

        &.active{
          border: 1px solid white;
        }
      }

      .step-row{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        .step-name{
          color: grey;
        }

        .bullet{
          border: 2px solid grey;
          border-radius: 100%;
          width: 30px;
          height: 30px;
          text-align: center;
          padding-top: 1px;
          margin-right: 15px;
          color: grey;
        }

        &.active .bullet {
          border: 2px solid white;
          color: white;
        }

        &.active .step-name{
          color: white;
        }
      }
    }
  }

  .form-display{
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;

    .form-container{
      padding: 50px 50px 0;
      border-radius: 20px;
      background-color: #fff;
      box-shadow: 0 0 15px 2.5px rgba(0, 0, 0, 0.1);
      width: 60%;

      &.wide{
        width: 100%;
      }

      .buttons-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .form-name{
        font-size: 34px;
        font-weight: bold;
        color: var(--dark-primary);
        margin: 0 0 15px -15px;
      }

      .form-fields{
        width: 100%;

        .form-text-field{
          margin: 5px 0;
        }
      }

      .title{
        font-size: 24px;
        font-weight: 500;
      }

      .title-smaller{
        font-size: 20px;
        font-weight: 500;
      }

      .form-fields-fullwidth{
        width: 100%;
        display: flex;

        .form-text-field{
          margin: 5px 0;
        }

        .column{
          flex: 1;

          &.left{
            margin-right: 15px;
          }

          &.right{
            margin-left: 15px;
          }
        }
      }
    }
  }
}