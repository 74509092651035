.help-page {
  @media screen and (max-width: 768px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  h2 {
    margin-top: 16px;
  }

  p {
    margin-bottom: 6px;
  }

  .help-page-image {
    width: 70%;
    min-width: 400px;
    border-radius: 20px;
    @media screen and (max-width: 768px) {
      width: 100%;
      min-width: 0;
    }
  }

  .help-page-vertical-image {
    height: 60vh;
    min-height: 200px;
    border-radius: 20px;
    @media screen and (max-width: 768px) {
      height: 80vh;
      min-height: 0;
    }
  }

  .help-page-square-image {
    width: 40%;
    min-width: 200px;
    border-radius: 20px;
    @media screen and (max-width: 768px) {
      width: 70%;
      min-width: 0;
    }
  }
}