.media-body-container{
  .delete-button{
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    background-color: var(--dark-primary);
    width: 22.5px;
    height: 22.5px;

    @media(max-width: 768px){
      width: 30px;
      height: 30px;
    }
  }

  .media-image-container{
    width: 30%;
    aspect-ratio: 1;
    margin-bottom: 10px;
    margin-top: 10px;

    @media (min-width: 769px) {
      &:nth-child(3n + 1) {
        margin-right: 2.5%;
      }

      &:nth-child(3n + 2) {
        margin-right: 2.5%;
        margin-left: 2.5%;
      }

      &:nth-child(3n) {
        margin-left: 2.5%;
      }
    }

    @media(max-width: 768px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  .add-media-button{
    background-color: lightgrey;
    width: 75%;
    height: 75%;

    &:hover{
      background-color: #c8c8c8;
    }
  }
}