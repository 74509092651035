.rich-text-editor-container {
  height: 50vh;

  @media (max-width: 768px){
    height: 65vh;
  }

  ul{
    padding-left: 20px;
  }

  ol{
    padding-left: 15px;
  }

  #mui-rte-root {
    height: 100%;
  }

  #mui-rte-container {
    height: 100%;
  }

  #mui-rte-toolbar {
    border: 1px solid grey;
    border-radius: 20px 20px 0 0;
  }

  #mui-rte-editor {
    height: calc(100% - 52px);
    border: 1px solid grey;
    border-radius: 0 0 20px 20px;
    padding: 5px 10px;

    @media (max-width: 768px){
      height: calc(100% - 92px);
    }
  }

  #mui-rte-toolbar{
    padding: 5px;
  }

  #mui-rte-editor-container {
    height: 100%;
  }

  .DraftEditor-root {
    height: 100%;
  }

  .DraftEditor-editorContainer {
    overflow: auto;
    height: 100%;
  }

  [class *= 'MUIRichTextEditor-placeHolder-'] {
    padding: 5px 10px !important;
  }
}