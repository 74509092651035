.terms-page{
  * {
    text-decoration: none;
    outline: none;
    font-size: 14px;
  }

  h1 {
    font-size: 48px;
    margin: calc(48px / 2) 0 calc(48px / 2) 0;
  }
  h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    display: block;
    color: #222;
    line-height: 130%;
    font-weight: 700;
  }

  a {
    color: var(--light-primary);
    text-decoration: underline;
    transition: all 0.2s linear;
  }

  a:hover {
    color: #222;
    text-decoration: none;
  }

  h3 {
    font-size: 36px;
    margin: calc(36px / 2) 0 calc(36px / 2) 0;
  }

  ul {
    list-style-type: none;
  }
  ol, ul {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  ul li {
    position: relative;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  ol li{
    margin-left: 20px;
    margin-bottom: 5px;
  }

  ul li::after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    background: var(--light-primary);
    position: absolute;
    top: 8px;
    left: -13px;
  }

  .project-manager h2 {
    line-height: 120%;
  }
  h2 {
    font-size: 42px;
    margin: calc(42px / 2) 0 calc(42px / 2) 0;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    line-height: 180%;
    text-transform: none;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}