.header-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .section-hero {
    width: 100%;
    max-width: 670px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transform: translateY(-100px);

    @media (max-width: 768px) {
      max-width: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }

    .resita-jobs-logo {
      width: 80%;
      margin-bottom: 20px;
    }

    h1 {
      margin-top: 0;
      margin-bottom: 24px;
      font-size: 50px;
      line-height: 70px;
      font-weight: 700;

      @media (max-width: 768px) {
        font-size: 52px !important;
        margin-bottom: 8px;
      }
    }

    .search-text-field {
      margin-top: 48px;
      position: relative;
      padding-left: 24px;
      padding-right: 24px;

      .search-input {
        min-height: 92px;
        padding-right: 194px;
        padding-left: 61px;
        border-style: solid;
        border-width: 1px;
        border-color: #f7f7fb;
        border-radius: 10px;
        background-image: url('../../images/svgs/search.svg');
        background-position: 20px 50%;
        background-size: auto;
        background-repeat: no-repeat;
        box-shadow: 0 20px 24px 0 rgba(21, 60, 245, 0.06), 0 5px 14px 0 rgba(5, 21, 46, 0.05);
        -webkit-transition: box-shadow 350ms ease, border-color 350ms ease;
        transition: box-shadow 350ms ease, border-color 350ms ease;
        color: #858c95;
        font-size: 20px;
        line-height: 38px;

        @media (max-width: 768px) {
          min-height: 70px;
          padding-right: 24px;
          padding-left: 58px;
          width: 100%;
        }
      }

      .search-input:hover {
        border-color: var(--light-primary);
        box-shadow: 0 24px 33px 0 rgba(21, 60, 245, 0.1), 0 7px 18px 0 rgba(5, 21, 46, 0.07);
      }

      .search-input:focus {
        border-color: var(--light-primary);
        color: #05152e;
      }

      .search-input:focus-visible {
        outline: none;
      }

      .search-input::-webkit-input-placeholder {
        color: #858c95;
      }

      .search-input:-ms-input-placeholder {
        color: #858c95;
      }

      .button-primary {
        all: unset;
        cursor: pointer;
        padding: 18px 24px;
        border-radius: 12px;
        background-color: var(--dark-primary);
        box-shadow: 0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2);
        -webkit-transition: box-shadow 300ms ease, background-color 300ms ease, -webkit-transform 300ms ease;
        transition: transform 300ms ease, box-shadow 300ms ease, background-color 300ms ease, -webkit-transform 300ms ease;
        color: #fff;
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        text-align: center;
        letter-spacing: 0.08em;
        text-transform: uppercase;
      }

      .button-primary:hover {
        background-color: var(--darker-primary);
        box-shadow: 0 3px 9px 0 rgba(5, 21, 46, 0.16), 0 14px 19px 0 rgba(21, 60, 245, 0.23);
        -webkit-transform: translate(0px, -2px);
        -ms-transform: translate(0px, -2px);
        transform: translate(0px, -2px);
        color: #fff;
      }

      .button-primary.large {
        padding: 22px 36px;
        font-size: 18px;
        line-height: 12px;
      }

      .button-primary.large.full-width {
        width: 100%;
      }

      .button-primary.full-width {
        display: block;
        -webkit-align-self: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
      }

      .button-primary.button-white {
        background-color: #fff;
        color: #153cf5;
      }

      .button-primary.button-header-primary {
        margin-left: 24px;
      }

      .button-primary.search {
        position: absolute;
        left: auto;
        top: 0;
        right: 20px;
        bottom: 0;
        margin-top: 18px;
        margin-right: 24px;
        margin-bottom: 18px;

        @media (max-width: 768px) {
          position: static;
          width: 100%;
          padding-top: 22px;
          padding-bottom: 22px;
          box-sizing: border-box;
        }
      }
    }
  }
}

.circle{
}

#circle-1 {
  position: absolute;
  left: -190px;
  top: -130px;
  width: 650px;
  height: 650px;
  background-color: #516682;
  opacity: 0.1;
  border-radius: 50%;
}

#circle-2 {
  position: absolute;
  left: 350px;
  top: 75px;
  width: 40px;
  height: 40px;
  background-color: var(--light-primary);
  opacity: 0.2;
  border-radius: 50%;

}

#circle-3 {
  position: absolute;
  left: -30px;
  top: 470px;
  width: 250px;
  height: 250px;
  background-color: #516682;
  opacity: 0.2;
  border-radius: 50%;

}

#circle-4 {
  position: absolute;
  right: -40px;
  bottom: -60px;
  width: 250px;
  height: 250px;
  background-color: #516682;
  opacity: 0.15;
  border-radius: 50%;

}

#circle-5 {
  position: absolute;
  right: -230px;
  bottom: 120px;
  width: 750px;
  height: 750px;
  background-color: var(--light-primary);
  opacity: 0.075;
  border-radius: 50%;

}

.page-container {
  .jobs-page {
    @media (max-width: 768px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .jobs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .applications-job-selector-container{
      display: flex;

      .applications-job-selector{
        font-size: 24px;
        font-weight: 600;
        color: var(--dark-primary)
      }
    }

    .applications-checkbox {
      .MuiTypography-root {
        font-size: 20px !important;
        font-weight: 500;
      }
    }

    @media(max-width: 768px){
      display: block;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 30px;
    }

    h2 {
      font-size: 28px;
    }
  }

  .new-job-button {
    background-color: var(--dark-primary);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), 0 14px 14px 0 rgba(0, 0, 0, 0.2);
    color: white;
    border-radius: 12px;
    padding: 14px 24px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    transition: transform 300ms ease, box-shadow 300ms ease, background-color 300ms ease, -webkit-transform 300ms ease;

    @media (max-width: 768px){
      margin-top: 24px;
    }

    &:hover{
      background-color: var(--darker-primary);
      box-shadow: 0 3px 9px 0 rgba(5, 21, 46, 0.16), 0 14px 19px 0 rgba(21, 60, 245, 0.23);
      -ms-transform: translate(0px, -2px);
      transform: translate(0px, -2px);
    }
  }
}

