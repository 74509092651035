@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    /* Colors */
    --black: #09090c;
    --grey: #a4b2bc;
    --white: #fff;
    --background: rgba(137, 171, 245, 0.37);
    --primary: #4481eb;
    --light-primary: #27528f;
    --dark-primary: #1F406D;
    --dark-primary-gradient: #254e85;
    --darker-primary: #142b4a;
}

body {
    margin: 0;
    width: 100vw;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    padding: 0 !important;
    /*overflow-y: visible !important;*/
    background-color: #f7f7f7;
    overscroll-behavior-y: none;
}

/*.MuiChip-label{*/
/*  color: var(--dark-primary);*/
/*}*/

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-container {
    padding-left: 7.5vw !important;
    padding-right: 7.5vw !important;
    width: 100vw !important;
}

a {
    color: var(--dark-primary)
}

/* total width */
*::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
    cursor: default !important;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
    background-color: #efefef;
    cursor: default !important;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #efefef;
    cursor: default !important;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
    display: none;
}

.disable-hover {
    pointer-events: none;
}

@media (max-width: 768px) {
    .only-large {
        display: none;
    }
}
@media (min-width: 769px) {
    .only-small {
        display: none;
    }
}