.media-body-container {
  .media-image {
    width: 30%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-top: 10px;

    @media (min-width: 1200px) {
      &:nth-child(3n + 1) {
        margin-right: 2.5%;
      }

      &:nth-child(3n + 2) {
        margin-right: 2.5%;
        margin-left: 2.5%;
      }

      &:nth-child(3n) {
        margin-left: 2.5%;
      }
    }

    @media (max-width: 1200px) and (min-width: 769px){
      width: 47.5%;

      &:nth-child(2n + 1){
        margin-right: 2.5%;
      }


      &:nth-child(2n){
        margin-left: 2.5%;
      }
    }

    @media(max-width: 768px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
