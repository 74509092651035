.background-container{
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -100;
  //background-image: linear-gradient(to bottom right, #f7f7f7, #7d96ba);
}

.form-page-container{
  width: 100vw;
  padding: 0 80px !important;

  @media (max-width: 768px){
    padding: 0 1rem 0 1rem !important;
  }

  .button-container{
    display: flex;
    justify-content: flex-end;
  }

  .page-title{
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;

    @media (max-width: 768px){
      font-size: 26px;
      height: 100px;
    }
  }

  .container-form{
    padding: 3.5% 10%;
    margin-bottom: 30px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 0 15px 2.5px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px){
      padding: 25px 20px;
    }

    .form-row{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &.space-between{
        align-items: flex-end;
        justify-content: space-between;
      }

      .left{
        margin-right: 25px;
      }

      .right{
        margin-left: 25px;
      }

      @media (max-width: 768px){
        flex-direction: column;

        .left{
          margin-right: 0;
        }

        .right{
          margin-left: 0;
        }
      }
    }
    .form-text-field{
      margin-bottom: 30px;

      @media(min-width: 769px) {
        &.width-50-large {
          width: 50%;
        }
      }
    }
  }

  div.solid {
    margin-left: -10%;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 120%;
    height: 2px;
    background-color: #ccc;
    border-radius: 4px;

    @media (max-width: 768px){
      margin-left: -10px;
      width: calc(100% + 20px);
    }
  }

  .form-row {
    .rich-text-editor-container {
      height: 350px;
      width: 100%;
    }

    &.editor-form-row{
      padding-bottom: 60px;

    }
  }
}