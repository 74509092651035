.experience-bar{
  width: 100%;
  height: 6px;
  background-color: lightgrey;
  border-radius: 6px;

  .bar{
    height: 6px;
    width: 0;
    background-color: var(--dark-primary);
    border-radius: 6px;
  }
}